<script setup>
import VOtpInput from 'vue3-otp-input'
import { warningToast, successToast, errorToast } from '@/helpers/showToast'
import { auth, sendPhone, sendSms } from '../api'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { ref } from 'vue'
import { useAuthStore } from '../store'
import ButtonBase from '@/UI/ButtonBase/ButtonBase.vue'
import AppProgressLinear from "@/components/AppProgressLinear/AppProgressLinear.vue";
import { useStore } from '@/states/index';

import SupportView from './SupportView.vue';

import { patchUser } from '@/screens/SettingsView/api';

const { t } = useI18n()

const options = [t('auth.callWithCode'), t('auth.send')]

const router = useRouter()
const otpRef = ref(null)
const authStore = useAuthStore()
const loading = ref(false)

const sendSmsCode = async () => {
  try {
    loading.value = true

    const request = authStore.authType === 0 ? sendPhone : sendSms;
    const { success } = await request({ auth: authStore.token })
    if (success) {
      successToast({
        description: t('auth.sended'),
      })
      otpRef.value.clearInput()
    } else {
      warningToast({ description: t('toast.somethingWrong') })
    }

    authStore.timeout = 60
  } catch (error) {
    console.error(error)
    warningToast({ description: t('toast.somethingWrong') })
  } finally {
    loading.value = false
  }
}

const store = useStore();

const requestPending = ref(false);
const sendOtpCode = async (code) => {
  requestPending.value = true;
  const {
    success,
    message,
    token: authToken,
  } = await auth({
    auth: authStore.token,
    pincode: code
  })

  if (success) {
    patchUser({ language: store.language })
  }

  if (!success) {
    errorToast({ description: message })
    requestPending.value = false;
    return
  }

  store.$patch({ authToken })
  router.replace('/')
  resetStep();
  authStore.phoneNumber = '';
}

function handlePaste(e) {
  let clipboardData, pastedData

  e.stopPropagation()
  e.preventDefault()

  clipboardData = e.clipboardData || window.clipboardData
  pastedData = clipboardData.getData('Text')

  const regex = /^[0-9]+$/

  if (!regex.test(pastedData)) {
    otpRef.value.clearInput()
  }
}

function resetStep() {
  authStore.step = 0
  authStore.token = ''
  authStore.timeout = 0
}

const sendOtpCodeFromButton = () => {
  try {
    const code = otpRef.value.otp.join('');
    sendOtpCode(code);
  } catch (error) {
    console.log(error);
    errorToast({ description: t('toast.somethingWrong') });
  }
}
</script>

<template>
  <div class="enter-otp-confirmation__step">
    <div class="auth__subtitle">
      <template v-if="authStore.authType === 0">
        {{ t('auth.call') }} <br>
        {{ t('auth.enterLastFourChar') }}
      </template>
      <template v-else>
        {{ t('auth.sended') }}<br>{{ t('auth.atNumber') }}
        {{ authStore.phoneNumber }}
      </template>
    </div>

    <v-otp-input
      ref="otpRef"
      value=""
      input-classes="enter-otp-confirmation__step__otp__input__item"
      separator=""
      :num-inputs="4"
      :should-auto-focus="true"
      :is-input-num="true"
      :is-disabled="requestPending"
      :class="[
        'enter-otp-confirmation__step__otp__input',
        {
          'enter-otp-confirmation__step__otp__input_request-pending': requestPending
        }
      ]"
      @on-complete="sendOtpCode"
      @paste="handlePaste"
    />

    <app-progress-linear
      :class="[
        'enter-otp-confirmation__step__request-pending',
        {
          'enter-otp-confirmation__step__request-pending_active': requestPending
        }
      ]"
    />

    <template v-if="authStore.authType === 0">
      <ButtonBase @click="sendOtpCodeFromButton">
        {{ t('common.send') }}
      </ButtonBase>
    </template>

    <button-base
      variant="text"
      class="enter-otp-confirmation__step__resend"
      @click="resetStep()"
    >
      {{ t('auth.changeNumber') }}
    </button-base>
    <button-base
      :loading="loading"
      variant="text"
      class="enter-otp-confirmation__step__resend"
      :disabled="Boolean(authStore.timeout)"
      @click="sendSmsCode()"
    >
      {{ options[authStore.authType] }}
      <br>
      <span v-if="authStore.timeout">
        {{ t('auth.through') }} {{ authStore.timeout }} {{ t('auth.sec') }}
      </span>
    </button-base>

    <SupportView />
  </div>
</template>
