import { api } from "@/app/api"
import { ShowcasesItemTypes } from "../constants/showcases"

export default {
  async getAll(accountId, params) {
    const { data } = await api.get(`/v1/showcases/${accountId}`, { params })

    const products = data.showcases.filter(item => item.type === 1);
    const services = data.showcases.filter(item => item.type === 2);
    const prices = data.showcases.filter(item => item.type === 3);

    return { data, products, services, prices };
  },

  async getProducts(accountId, params) {
    return await api.get(`/v1/showcases/${accountId}`, {
      params: {
        type: ShowcasesItemTypes.products,
        ...params
      }
    })
  },

  async getItems(accountId, params = {}) {
    const { data } = await api.get(`/v1/showcases/${accountId}`, {
      params
    })

    return { data: data.showcases, total: data.total }
  },

  async getItemById(id) {
    const { data } = await api.get(`/v1/showcases/item/${id}`)

    return { data: data.item }
  },

  async create(accountId, payload) {
    return await api.post(`/v1/showcases/${accountId}`, payload)
  },

  async update(accountId, payload) {
    return await api.put(`/v1/showcases/${accountId}`, payload)
  },

  async delete(id) {
    return await api.delete(`/v1/showcases`, {
      data: {
        id
      }
    })
  }
}
